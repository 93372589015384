import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styles from "./PicsGrid.module.css";

const PicsGrid = ({ pic1, alt1, pic2, alt2, pic3, alt3, hasGap }) => {
  const gapStyles = hasGap ? styles.hasGap : "";
  return (
    <div className={`${styles.PicsGrid} ${gapStyles}`}>
      <Img className={styles.Picture} alt={alt1} fluid={pic1} />
      <Img className={styles.Picture} alt={alt2} fluid={pic2} />
      <Img className={styles.Picture} alt={alt3} fluid={pic3} />
    </div>
  );
};

PicsGrid.propTypes = {
  /** First picture of the grid */
  pic1: PropTypes.object.isRequired,

  /** Alt text of the first picture of the grid */
  alt1: PropTypes.string.isRequired,

  /** Second picture of the grid */
  pic2: PropTypes.object.isRequired,

  /** Alt text of the second picture of the grid */
  alt2: PropTypes.string.isRequired,

  /** Third picture of the grid */
  pic3: PropTypes.object.isRequired,

  /** Alt text of the third picture of the grid */
  alt3: PropTypes.string.isRequired,

  /** Whether the grid has a gap */
  hasGap: PropTypes.bool,
};

export default PicsGrid;
